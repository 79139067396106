import { JsonObject, JsonProperty } from 'json2typescript';
import { DistanceConverter, NumberToStringConverter, StringToNumberConverter } from './converters';
import HotelFacilityModel from './HotelFacilityModel';
import HotelFareModel from './HotelFareModel';
import HotelExtraModel from './HotelExtraModel';
import { HotelFeatureModel } from '@src/models';
import { ACCOMMODATION_TYPES } from '@src/shared/src/const/app';

@JsonObject('HotelModel')
export default class HotelModel {
  @JsonProperty('id', NumberToStringConverter) public id: string = undefined;
  @JsonProperty('name', String) public name: string = '';
  @JsonProperty('price', Number) public price: number = -1;
  @JsonProperty('price_per_room_night', Number) public pricePerRoomNight: number = -1;
  @JsonProperty('currency', String) public currency: string = '';
  @JsonProperty('address', String) public address: string = '';
  @JsonProperty('comfort_class', Number) public comfortClass: number = 0;
  @JsonProperty('picture_urls', [String], true) public pictureURLs: string[] = [];
  @JsonProperty('primary_picture_url', String) public primaryPictureURL: string = '';
  @JsonProperty('description', String, true) public description: string = '';
  @JsonProperty('latitude', Number, true) public latitude: number = -1;
  @JsonProperty('longitude', Number, true) public longitude: number = -1;
  @JsonProperty('services', [String], true) public services: string[] = [];
  @JsonProperty('amenities', [String], true) public amenities: string[] = [];
  @JsonProperty('facilities', [HotelFacilityModel], true) public facilities: HotelFacilityModel[] = [];
  @JsonProperty('fares', [HotelFareModel], true) public fares: HotelFareModel[] = [];
  @JsonProperty('details_status', String) public detailsStatus: string = undefined;
  @JsonProperty('distance', DistanceConverter) public distance: number = 0;
  @JsonProperty('original_currency', String) public originalCurrency: string = '';
  @JsonProperty('expanded', Boolean, true) public expanded: boolean = false;
  @JsonProperty('negotiated_rates', Boolean) public negotiatedRates: boolean = false;
  @JsonProperty('org_favorited', Boolean) public orgFavorited: boolean = false;
  @JsonProperty('favorited', Boolean) public favorited: boolean = false;
  @JsonProperty('accommodation_type', String, true) public accommodationType: ACCOMMODATION_TYPES = undefined;
  @JsonProperty('filters', [HotelFeatureModel], true) public features: HotelFeatureModel[] = [];
  // set default to 0 in case we get `null` for user_rating so the sorting etc works as expected
  @JsonProperty('user_rating', StringToNumberConverter, true) userRating: number = 0;
  @JsonProperty('num_reviews', Number, true) numReviews: number = undefined;
  @JsonProperty('original_user_rating', String, true) originalUserRating: string = undefined;
  @JsonProperty('original_user_rating_scale', Number, true) originalUserRatingScale: number = undefined;
  @JsonProperty('extra', HotelExtraModel, true) public extra:HotelExtraModel = undefined;
}
