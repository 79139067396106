import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { isNilOrEmpty } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
// Constants
import { ENVIRONMENT, STATUS } from '@src/shared/src/const/app';
// Actions
import { selectors } from '@src/shared/src';
// Models
import { HotelModel, BasketItemModel, CoordinatesModel } from '@src/shared/src/models';
// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';
// Components
import HotelDescription from './HotelDescription';
import HotelFacilities from './HotelFacilities';
import { HotelFare } from './HotelFare';
import HotelFareMobile from './HotelFareMobile';
import HotelImageGallery from './HotelImageGallery';
import HotelMapDetails from './HotelMapDetails';
import { LoaderHotelDetails } from '@pod/trips/components';
import { BookedHotelItemInfo } from '@src/pod/checkout/components';
import { HotelCheckinInfo } from './HotelCheckinInfo';
// Styles
import '../styles/HotelDetails.scss';

type Props = ConnectedRedux<IRootState> &
  AppContextProp & {
    hotel: HotelModel;
    hotelRoomCount?: number;
    env: ENVIRONMENT;
    basketItems: BasketItemModel[];
    basketStatus: STATUS;
    searchArrCoor: CoordinatesModel;
    isSelectingHotel?: boolean;
    isBasketEditable?: boolean;
    onSelectHotel: (hotelFareIds: string[]) => void;
  };

class HotelDetails extends React.Component<Props> {
  private getRoomSelectionMarkup = () => {
    return (
      <div className="tcp-hotel-details-fares">
        {this.props.appContext.isMediaBPNotMobile ? (
          <HotelFare
            accommodationType={this.props.hotel.accommodationType}
            onSelectHotel={this.props.onSelectHotel}
            isSelectingHotel={this.props.isSelectingHotel}
            env={this.props.env}
            fares={this.props.hotel.fares}
            hotelRoomCount={this.props.hotelRoomCount}
            isBasketEditable={this.props.isBasketEditable}
            facilities={this.props.hotel.amenities}
          />
        ) : (
          <HotelFareMobile
            env={this.props.env}
            fares={this.props.hotel.fares}
            onSelectHotel={this.props.onSelectHotel}
          />
        )}
      </div>
    );
  };

  private getDetailsImagesMarkup = (hotel) => {
    if (!isNilOrEmpty(hotel.pictureURLs)) {
      return (
        <div className="tcp-hotel-details-images">
          <HotelImageGallery pictureURLs={hotel.pictureURLs} />
        </div>
      );
    }
  };

  render() {
    const { hotel, searchArrCoor, appContext, env } = this.props;

    if (isNilOrEmpty(hotel.fares)) {
      return <LoaderHotelDetails finished={false} />;
    }

    return (
      <div className="tcp-hotel-details">
        {appContext.isMediaBPNotMobile ? <BookedHotelItemInfo hotel={hotel} env={env} /> : ''}
        <div className="tcp-hotel-details-main">
          <HotelDescription description={hotel.description} />
        </div>
        <div className="tcp-hotel-details-side">
          <div className="tcp-hotel-details-title">{t('hotel.details.facilities')}</div>
          <HotelFacilities mode={'HOTEL'} facilities={hotel.services} />
          {hotel.extra && <HotelCheckinInfo extra={hotel.extra} />}
        </div>
        {this.getDetailsImagesMarkup(hotel)}
        {this.getRoomSelectionMarkup()}
        <div className="tcp-hotel-details-map-title">{t('hotel.details.mapTitle')}</div>
        <HotelMapDetails hotel={hotel} destCoord={searchArrCoor} />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  basketItems: selectors.checkout.basketItems(state.checkout),
  basketStatus: selectors.checkout.basketStatus(state),
  searchArrCoor: selectors.search.searchArrCoor(state.search),
  isBasketEditable: selectors.checkout.isBasketEditable(state.checkout),
});
export default connect(mapState)(withAppContext(HotelDetails));
