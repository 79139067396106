import * as React from 'react';
import { PriceBreakdownModel } from '@src/shared/src/models';
import { useTranslation } from 'react-i18next';
import { formatCents } from '@src/shared/src/util/general';
import { Modal } from '@toolkit/ui';
import '../styles/BookingItemPriceBreakdown.scss';

type BookingItemPriceBreakdownProps = {
  totals: PriceBreakdownModel;
};

export const BookingItemPriceBreakdown: React.FC<BookingItemPriceBreakdownProps> = ({
  totals: { priceExcludingTaxes, propertyFees, priceExcludingPropertyFees, taxes, total },
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <div className="lp-booking-info-sub-total booking-item-price-breakdown">
      <p>
        {t('BookingInfo.label.priceExcludingTaxes')}
        <span className="lp-booking-info-sub-total-price">{formatCents(priceExcludingTaxes)}</span>
      </p>

      <p>
        {t('BookingInfo.label.taxes')}
        <button onClick={() => setShowModal(true)} className='booking-item-price-breakdown-info'>
          <i className="material-symbols">info</i>
        </button>
        <span className="lp-booking-info-sub-total-price">{formatCents(taxes)}</span>
      </p>

      <p>
        {t('BookingInfo.label.propertyFees')}
        <span className="lp-booking-info-sub-total-price">{formatCents(propertyFees)}</span>
      </p>

      <p>
        {t('BookingInfo.label.priceExcludingPropertyFees')}
        <span className="lp-booking-info-sub-total-price">
          {formatCents(priceExcludingPropertyFees)}
        </span>
      </p>

      <hr />

      <p>
        {t('BookingInfo.label.subTotal')}
        <span className="lp-booking-info-sub-total-price">{formatCents(total)}</span>
      </p>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={t('BookingInfo.taxes.modal.title')}>
        {t('BookingInfo.taxes.modal.content')}
      </Modal>
    </div>
  );
};
