import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Fees')
class Fees {
  @JsonProperty('optional', String, true) optional: string = undefined;
  @JsonProperty('mandatory', String, true) mandatory: string = undefined;
}

@JsonObject('Checkin')
class Checkin {
  @JsonProperty('end_time', String, true) endTime: string = undefined;
  @JsonProperty('begin_time', String, true) beginTime: string = undefined;
  @JsonProperty('instructions', String, true) instructions: string = undefined;
  @JsonProperty('special_instructions', String, true) specialInstructions: string = undefined;
}

@JsonObject('Checkout')
class Checkout {
  @JsonProperty('time', String, true) time: string = undefined;
}

@JsonObject('Policies')
class Policies {
  @JsonProperty('know_before_you_go', String, true) knowBeforeYouGo: string = undefined;
}

@JsonObject('HotelExtraModel')
export default class HotelExtraModel {
  @JsonProperty('fees', Fees, true) fees: Fees = undefined;
  @JsonProperty('checkin', Checkin, true) checkin: Checkin = undefined;
  @JsonProperty('checkout', Checkout, true) checkout: Checkout = undefined;
  @JsonProperty('policies', Policies, true) policies: Policies = undefined;
}
