export const nsValue = 'main';
export const SERVER_TIMEOUT = 240 * 1000;
export const SERVER_TIMEOUT_MAX = 300 * 1000;
export const MIN_LONG_HAUL_DISTANCE: number = 4000000; // 4000 Km
export const INBOUND_MIN_DEP_INVALID_INBOUNDS_FILTER = 30; // 30 minutes
export const WS_STATUS_CHECK_INTERVAL = 5 * 1000;
export const MAX_SEARCH_DAYS = 330; // 330 days

export enum ROLES {
  READ_ONLY_TRAVELLER = 'read_only_traveller',
  TRAVELLER = 'traveller',
  MANAGER = 'manager',
  TRAVEL_ASSISTANT = 'travel_assistant',
  ADMIN = 'admin',
  READ_ONLY_ADMIN = 'read_only_admin',
  ACCOUNTANT = 'accountant',
  GUEST = 'guest',
}

export enum VEHICLE_TYPES {
  PLANE = 'PLANE',
  BUS = 'BUS',
  TRAIN = 'TRAIN',
  FOOT = 'FOOT',
  UNKNOWN = 'UNKNOWN',
  PUBLIC_TRANSIT = 'PUBLIC_TRANSIT',
  TAXI = 'TAXI',
  // Below types are from https://developers.google.com/maps/documentation/javascript/directions#VehicleType
  RAIL = 'RAIL',
  METRO_RAIL = 'METRO_RAIL',
  SUBWAY = 'SUBWAY',
  TRAM = 'TRAM',
  MONORAIL = 'MONORAIL',
  HEAVY_RAIL = 'HEAVY_RAIL',
  COMMUTER_TRAIN = 'COMMUTER_TRAIN',
  HIGH_SPEED_TRAIN = 'HIGH_SPEED_TRAIN',
  INTERCITY_BUS = 'INTERCITY_BUS',
  TROLLEYBUS = 'TROLLEYBUS',
  SHARE_TAXI = 'SHARE_TAXI',
  FERRY = 'FERRY',
  CABLE_CAR = 'CABLE_CAR',
  GONDOLA_LIFT = 'GONDOLA_LIFT',
  FUNICULAR = 'FUNICULAR',
  OTHER = 'OTHER',
  WALKING = 'WALKING',
  RENTAL = 'RENTAL',
}

export enum TIMEOUTS {
  SEARCH = 300000,
}

export enum OVERLAY_TIMEOUT {
  DELAYED_SEARCH = 75000,
}

export enum CONFIRM_TIMEOUT {
  DELAYED_CONFIRMATION = 60000,
}

export enum LOADER_TIMEOUT {
  DEFAULT_DURATION = 80000,
}

export enum ROUTE_KEYS {
  SEARCH = 'search',
  OUTBOUND = 'outbound',
  INBOUND = 'inbound',
  HOTEL = 'hotel',
}

export enum LOG_TYPE {
  WARNING = 1,
  ERROR = 2,
  DEBUG = 3,
  INFO = 4,
}

export enum SCREEN_BP {
  PHONE = 420,
}

export enum NOTIF_TYPE {
  SUCCESS = 0,
  ERROR = 1,
  WARNING = 2,
}

export enum SEAT_RES_STATUS {
  FRESH = "fresh",
  NOT_REQUESTED = 'not_requested',
  NOT_AVAILABLE = 'not_available',
  AVAILABLE = 'available',
  INCLUDED = 'included',
}

export enum TERMS_SEAT_STATUS {
  SEAT_UNKNOWN = 'seat_unknown',
  SEAT_INCLUDED = 'seat_included',
  SEAT_EXCLUDED = 'seat_excluded',
}

export enum TERMS_REBOOKING_STATUS {
  REBOOKABLE_UNKNOWN = 'rebookable_unknown',
  REBOOKABLE = 'rebookable',
  NOT_REBOOKABLE = 'not_rebookable',
}

export enum TERMS_CANCELLATION_STATUS {
  CANCELABLE_UNKNOWN = 'cancelable_unknown',
  CANCELABLE = 'cancelable',
  NOT_CANCELABLE = 'not_cancelable',
}

export enum ENVIRONMENT {
  SEARCH = 1,
  CHECKOUT = 2,
  PAYMENT = 3,
  CONFIRMATION = 4,
  BOOKED = 5,
  ADMIN = 6,
  JOURNEY = 7,
}

export enum APPROVAL_STATUS {
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
}

export enum TRANSIT_TYPES {
  TAXI = 'taxi',
  PUBLIC_TRANSIT = 'public_transit',
  NONE = 'none',
}

export enum CENTS_CONVERSION_TYPES {
  FLOOR = 'floor',
  ROUND = 'round',
  NONE = 'none',
}

export enum STATUS {
  PREPARE_FAILED = 'prepare_failed',
  PREPARE_FINISHED = 'prepare_finished',
  CONFIRM_FAILED = 'confirm_failed',
  CONFIRM_PENDING = 'confirm_pending',
  CONFIRM_FINISHED = 'confirm_finished',
  FRESH = 'fresh',
  FINISHED = 'finished',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress',
  TRIGGERED = 'triggered',
  EVENTS_FINISHED = 'events_finished',
}

export enum DIRECTION {
  OUTWARD = 'outward',
  INBOUND = 'inbound',
}

export const enum ERRORS {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR', // 500
  NOT_FOUND = 'NOT_FOUND', // 404
  NOT_AUTHORIZED = 'NOT_AUTHORIZED', // 411
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY', // 422
  TIMEOUT = 'TIMEOUT',
  FAILED = 'FAILED',
  DETAILS_FAILED = 'DETAILS_FAILED',
}

export const enum TIME_WINDOW_TYPE {
  ANYTIME = 'ANYTIME',
  EARLY = 'EARLY',
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
  NIGHT = 'NIGHT',
}

export const enum DEBOUNCE_TIME {
  INIT_FILTER = 50,
  APPLY_FILTER = 30,
  PASSENGERS_SUGGESTIONS = 300,
}

export const enum TRIP_DIRECTION {
  DEP = 'dep',
  ARR = 'arr',
}

export const enum FLIGHTS_CABIN_CLASS {
  FIRST = 1,
  BUSINESS = 2,
  PREMIUM_ECONOMY = 3,
  ECONOMY = 4,
}

export const enum DATE_FORMAT_TYPES {
  SHORT_DATE = 1,
  SHORT_TIME = 2,
  DAY_LONG_DATE = 3,
  DAY_SHORT_DATE = 4,
  LONG_TIME = 5,
  LONG_DATE_TIME = 6,
  SHORT_DAY_OF_WEEK = 7,
  LONG_DAY_OF_WEEK = 8,
  MONTH_YEAR = 9,
  YEAR_LONG_DATE = 10,
  LONG_MONTH = 11,
}

export const enum ERROR_SEVERITY {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export const enum SEARCH_TYPE {
  ALL = 1, // TODO: rename to ROUNDTRIP
  HOTEL = 2,
  RENTAL = 3,
  OUTBOUND = 4,
  MULTICITY = 5,
}

export const enum TRAIN_OPERATORS {
  DEUTSCHEBAHN = 'DeutscheBahn',
  SNCF = 'SNCF',
  EUROSTAR = 'EUROSTAR',
  SBB = 'SBB',
  CFF = 'CFF',
  FFS = 'FFS',
  ATOC = 'ATOC',
  TGV_LYRIA = 'TGV LYRIA',
  THALYS = 'THALYS',
  WESTBAHN = 'WEST',
  WESTBAHN_TEST = 'WTES'
}

export const enum TRANSPORT_SORT_VALUES {
  PRICE = 'price',
  DURATION = 'duration',
  DEP_AT = 'dep_at',
  ARR_AT = 'arr_at',
}

export const enum HOTEL_SORT_VALUES {
  RECOMMENDATION_SCORE = 'recommendation_score',
  STARS = 'stars',
  TOTAL_PRICE = 'total_price',
  RATING = 'rating',
  DISTANCE = 'distance',
}

export const enum LANG {
  EN_US = 'en-US',
  DE_DE = 'de-DE',
  FR_FR = 'fr-FR',
  DE_DE_ALL4ONE = 'de_de_all4one',
  EN_US_ALL4ONE = 'en_us_all4one',
}

export enum ACCOMMODATION_TYPES {
  HOTEL = 'hotel',
  VACATION_RENTAL = 'vacation_rental',
}

export enum COST_CENTER_CATEGORIES {
  COST_CENTER = 'cost_center',
  COST_UNIT = 'cost_unit',
}

export const enum AGGREGATORS {
  EXPEDIA_HOTEL = 'expedia_hotel',
}
