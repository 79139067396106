import * as React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { map, formatCents, path, sortBy } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import { selectors } from '@src/shared/src';
// Constants
import { ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
// Models
import { IRootState } from '@src/store';
import {
  BookingItemModel,
  BookingHotelFareModel,
  BookingTransportFareModel,
} from '@src/shared/src/models';
// Interfaces
// Components
import { TransportationBookingInfo } from '@src/components/TransportationBookingInfo';
import BookingRentalFareModel from '@src/shared/src/models/BookingRentalFareModel';
import { BookingItemPriceBreakdown } from './BookingItemPriceBreakdown';
// Styles
import '../styles/BookingInfo.scss';
import CheckoutPrice from './CheckoutPrice';

type Props = {
  bookingItems: BookingItemModel[];
  env: ENVIRONMENT;
};

const BookingInfo: React.FC<Props> = ({ env, bookingItems }) => {
  const bookingHasAirbnb = useSelector(selectors.checkout.isAnyBookingItemAirbnb);
  const profile = useSelector((state: IRootState) => state.adminUser.profile);

  const getHotelBookingMarkup = (fare: BookingHotelFareModel) => {
    return (
      <div className="lp-booking-info-item" key={`fare-hotel-${fare.id}`}>
        <div className="lp-booking-info-item-inner">
          <div className="lp-booking-info-item-title">
            {bookingHasAirbnb ? t('global.airbnb') : t('global.hotel')}
          </div>
          <b>{t('BookingItem.label.name')}:</b>
          &nbsp;{fare.hotel.name}
        </div>
      </div>
    );
  };

  const getRentalBookingMarkup = (fare: BookingRentalFareModel) => {
    return (
      <div className="lp-booking-info-item" key={`fare-rental-${fare.id}`}>
        <div className="lp-booking-info-item-inner">
          <div className="lp-booking-info-item-title">
            {t('BookingInfo.booking.subTitle.rental')}
          </div>
          <div>
            <b>{t('BookingItem.label.carType')}:</b>
            &nbsp; {`${fare.vehicleExamples[0]} ${t('rental.titel.similar')}`}
          </div>
        </div>
      </div>
    );
  };

  const renderBookingTotal = (totalPrice: number) => (
    <>
      <CheckoutPrice label={t('BookingInfo.label.subTotal')} price={totalPrice} />
      <div className="lp-booking-info-sub-total">
        {t('BookingInfo.label.subTotal')}:
        <span className="lp-booking-info-sub-total-price">{formatCents(totalPrice)}</span>
      </div>
    </>
  );

  const hotelBookings = bookingItems.filter((booking) => booking.fareType === 'hotel_fare');
  const transportBookings = bookingItems.filter((booking) => booking.fareType === 'transport_fare');
  const rentalBookings = bookingItems.filter((booking) => booking.fareType === 'rental_fare');

  return (
    <div className="lp-booking-info">
      <div className="lp-booking-info-title">{t('BookingInfo.title.bookingInformation')}</div>
      <div>
        {transportBookings.length === 1 &&
          map((bookingItem: BookingItemModel) => {
            const transportFares = sortBy(path(['segment', 'depAt']))(bookingItem.transportFares);
            return (
              <div key={`booking-item-${bookingItem.id}`}>
                <div className="lp-booking-info-items">
                  {map(
                    (fare: BookingTransportFareModel) => (
                      <TransportationBookingInfo
                        key={fare.id}
                        fare={fare}
                        env={env}
                        bookingItemSeatReservationInfo={bookingItem.seatReservation}
                        profile={profile}
                      />
                    ),
                    transportFares,
                  )}
                </div>
                {renderBookingTotal(bookingItem.totalPrice)}
              </div>
            );
          }, transportBookings)}

        {transportBookings.length > 1 &&
          map((bookingItem: BookingItemModel) => {
            return (
              <div key={`booking-item-${bookingItem.id}`}>
                <div className="lp-booking-info-items">
                  {map(
                    (fare: BookingTransportFareModel) => (
                      <TransportationBookingInfo
                        key={fare.id}
                        fare={fare}
                        env={env}
                        bookingItemSeatReservationInfo={bookingItem.seatReservation}
                        profile={profile}
                      />
                    ),
                    bookingItem.transportFares,
                  )}
                </div>
                {renderBookingTotal(bookingItem.totalPrice)}
              </div>
            );
          }, sortBy(path(['transportFares', 0, 'segment', 'depAt']))(transportBookings))}

        {map((bookingItem: BookingItemModel) => {
          return (
            <div key={`booking-item-${bookingItem.id}`}>
              <div className="lp-booking-info-items">
                {map(
                  (fare: BookingRentalFareModel) => getRentalBookingMarkup(fare),
                  bookingItem.rentalFares,
                )}
              </div>
              {renderBookingTotal(bookingItem.totalPrice)}
            </div>
          );
        }, rentalBookings)}

        {hotelBookings.map((bookingItem) => {
          return (
            <div key={`booking-item-${bookingItem.id}`}>
              {bookingItem.hotelFares.map((fare) => (
                <div key={`hotel-fare-${fare.id}`}>
                  <div className="lp-booking-info-items">{getHotelBookingMarkup(fare)}</div>
                  {fare.totals ? (
                    <BookingItemPriceBreakdown totals={fare.totals} />
                  ) : (
                    renderBookingTotal(bookingItem.totalPrice)
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookingInfo;
