import * as React from 'react';
import classNames from 'classnames';

// Utils
import { t } from '@toolkit/util/i18n';
import { take } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
// Components

// Styles
import '../styles/HotelFacilities.scss';
import { Link } from '@toolkit/ui';

interface IProps {
  facilities:string[];
  mode:'HOTEL' | 'ROOM';
}
interface IState {
  overlayOpen:boolean;
}
export default class HotelFacilities extends React.Component<IProps, IState> {
  public state:IState = {
    overlayOpen: false,
  };

  private getFacilitiesMarkup = (facilities:string[]) => {
    return (
      <ul>
        { take(6, facilities).map((currFacility: string, idx: number) => (
          <li key={`${currFacility}-${idx}`}>
            <i className="icon-info" aria-hidden="true" />
            {currFacility}
          </li>
        ))}
      </ul>
    );
  }

  private getOverlayMarkup = (facilities:string[]) => {
    return (
      <div className="tcp-hotel-facilities-overlay">
        <div className="tcp-hotel-facilities-overlay-content">
         <button className="tcp-hotel-facilities-overlay-close" onClick={() =>  this.setState({ overlayOpen: false})}>
            <i className="icon-close2" />
          </button>
          <h3 className="tcp-hotel-facilities-overlay-title">
            {t('HotelFacilities.title')}
          </h3>
          <ul>
            {facilities.map((currFacility: string, idx: number) => (
              <li key={`${currFacility}-${idx}`}>
                <i className="icon-check2" aria-hidden="true" />
                {currFacility}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  private openOverlay = () => {
      this.setState({ overlayOpen: true});
  }

  public render() {
    const { facilities, mode } = this.props;
    const mainClass = classNames('tcp-hotel-facilities',{
      'tcp-hotel-facilities-column': (mode === 'ROOM')
    });

    return (
      <div className={mainClass}>
        <div className="tcp-hotel-facilities-inner" onClick={this.openOverlay}>
          {this.getFacilitiesMarkup(facilities)}
        </div>
        <Link small onClick={this.openOverlay}>
            {t('global.showMore')}
        </Link>
        {this.state.overlayOpen && this.getOverlayMarkup(facilities)}
      </div>
    );
  }
}
