import * as React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

// Utils
import { isNilOrEmpty } from '@src/shared/src/util/general';
import { checkoutHooks } from '@src/services';
// Constants
// Actions & Selectors
import { isRebookingSearch } from '@src/shared/src/selectors/searchSelectors';
// Models
import { PolicyViolationModel, AccountingInvoiceProfileModel } from '@src/shared/src/models';
// Components
import { CheckoutFooter, CheckoutFooterPayment } from '@pod/checkout/components';
import CheckoutPaymentAccountingForm from './CheckoutPaymentAccountingForm';
import CheckoutPaymentLegalNoticeForm from './CheckoutPaymentLegalNoticeForm';
import CheckoutPaymentPolicyJustificationsForm from './CheckoutPaymentPolicyJustificationsForm';
// Styles

type Props = {
  onSubmit: any;
  policyViolations: PolicyViolationModel[];
  totalPrice: number;
  isConfirmingBooking: boolean;
  operators: string[];
  vehicles: string[];
  aggregators: string[];
  accountingInvoiceProfiles: AccountingInvoiceProfileModel[];
};
const CheckoutPaymentForm: React.FC<Props> = (props: Props) => {
  const purposeOfTripRequired = checkoutHooks.useIsPurposeOfTripRequired();
  const referenceCodeRequired = checkoutHooks.useIsReferenceCodeRequired();
  const costCenterRequired = checkoutHooks.useIsCostCenterRequired();
  const costUnitRequired = checkoutHooks.useIsCostUnitRequired();
  const justificationRequired = checkoutHooks.useIsJustificationRequired();
  const rebookingSearch = useSelector(isRebookingSearch);

  return (
    <React.Fragment>
      <Form
        onSubmit={props.onSubmit}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {!rebookingSearch && (
              <CheckoutPaymentAccountingForm
                accountingInvoiceProfiles={props.accountingInvoiceProfiles}
                purposeOfTripRequired={purposeOfTripRequired}
                referenceCodeRequired={referenceCodeRequired}
                costCenterRequired={costCenterRequired}
                costUnitRequired={costUnitRequired}
              />
            )}
            {!isNilOrEmpty(props.policyViolations) && (
              <CheckoutPaymentPolicyJustificationsForm
                policyViolations={props.policyViolations}
                justificationRequired={justificationRequired}
              />
            )}
            <CheckoutPaymentLegalNoticeForm
              operators={props.operators}
              vehicles={props.vehicles}
              aggregators={props.aggregators}
            />
            <CheckoutFooter totalPrice={props.totalPrice}>
              <CheckoutFooterPayment isConfirmingBooking={props.isConfirmingBooking} />
            </CheckoutFooter>
          </form>
        )}
      />
    </React.Fragment>
  );
};
export default CheckoutPaymentForm;
