import * as React from 'react';
import * as sanitizeHTML from 'sanitize-html';

// Utils
import { useTranslation } from 'react-i18next';
// Constants
// Actions, Models & Interfaces
import { HotelExtraModel } from '@src/shared/src/models';
// Components
import { Link, Modal } from '@toolkit/ui';
// Styles
import '../styles/HotelCheckinInfo.scss';

type Props = {
  extra: HotelExtraModel;
};

const getCheckinTime = (checkin: HotelExtraModel['checkin']) => {
  if (checkin.endTime) return `${checkin.beginTime} - ${checkin.endTime}`;
  return checkin.beginTime;
};

export const HotelCheckinInfo: React.FC<Props> = ({
  extra: { checkin, checkout, fees, policies },
}) => {
  const [overlayOpen, setOverlayOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <div className="hotel-checkin-info">
      <div className="tcp-hotel-details-title">{t('hotel.details.checkin-info')}</div>
      <div className="hotel-checkin-info-time">
        <i className="material-symbols">login</i>
        {t('HotelCheckinInfo.checkin.time')}: {getCheckinTime(checkin)}
      </div>
      <div className="hotel-checkin-info-time">
        <i className="material-symbols">logout</i>
        {t('HotelCheckinInfo.checkout.time')}: {checkout.time}
      </div>
      <Link small onClick={() => setOverlayOpen(true)}>
        {t('global.showMore')}
      </Link>
      <Modal
        isOpen={overlayOpen}
        onClose={() => setOverlayOpen(false)}
        title={t('HotelCheckinInfo.title')}>
        <div className="hotel-checkin-info-overlay">
          <div className="hotel-checkin-info-overlay-times">
            <div className="hotel-checkin-info-overlay-times-inner">
              <i className="material-symbols">login</i>
              <div>
                {t('HotelCheckinInfo.checkin.time')}
                <br />
                <strong>{getCheckinTime(checkin)}</strong>
              </div>
            </div>
            <div className="hotel-checkin-info-overlay-times-inner">
              <i className="material-symbols">logout</i>
              <div>
                {t('HotelCheckinInfo.checkout.time')}
                <br />
                <strong>{checkout.time}</strong>
              </div>
            </div>
          </div>
          <div className="hotel-checkin-info-overlay-description">
            {checkin.instructions && (
              <>
                <h2>{t('HotelCheckinInfo.checkin.instructions')}</h2>
                <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(checkin.instructions) }} />
                <hr />
              </>
            )}

            {checkin.specialInstructions && (
              <>
                <h2>{t('HotelCheckinInfo.checkin.specialInstructions')}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(checkin.specialInstructions) }}
                />
                <hr />
              </>
            )}

            {fees && (
              <>
                <h2>{t('HotelCheckinInfo.checkin.fees')}</h2>
                {fees.mandatory && (
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(fees.mandatory) }} />
                )}
                {fees.optional && (
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(fees.optional) }} />
                )}
                <hr />
              </>
            )}

            {policies?.knowBeforeYouGo && (
              <>
                <h2>{t('HotelCheckinInfo.checkin.policy')}</h2>
                <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(policies.knowBeforeYouGo) }} />
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
